<template>
  <div>
    <validation-observer
      ref="refEditAttachmentFormObserver"
      #default="{ handleSubmit }"
    >
      <b-modal
        id="edit-attachment-modal"
        ref="editAttachmentModal"
        size="lg"
        :title="$t('attachment.edit')"
        :cancel-title="$t('shared.btn.cancel')"
        cancel-variant="outline-secondary"
        :ok-title="$t('shared.btn.save')"
        :ok-disabled="processing"
        @ok.prevent="handleSubmit(editAttachment)"
        @show="resetAttachment"
      >
        <rc-overlay
          :show="processing"
          no-fade
          variant="white"
          class="h-100"
        >
          <form
            id="edit-attachment-form"
            autocomplete="off"
            @submit.stop.prevent
          >

            <validation-provider
              #default="validationContext"
              :name="$t('attachment.form.file.label.title')"
              rules="required|min:2"
            >
              <b-form-group
                :label="$t('attachment.form.file.label.title')"
                label-for="attachment-file-title"
              >
                <b-form-input
                  id="attachment-file-title"
                  v-model="value.title"
                  type="text"
                  autocomplete="off"
                  minlength="2"
                  maxlength="250"
                  spellcheck="false"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="type==='url'"
              #default="validationContext"
              :name="$t('attachment.form.link.label.url')"
              rules="required|url"
            >
              <b-form-group
                :label="$t('attachment.form.link.label.url')"
                label-for="attachment-url"
              >
                <b-form-input
                  id="attachment-url"
                  v-model="value.url"
                  type="text"
                  autocomplete="off"
                  minlength="2"
                  maxlength="250"
                  spellcheck="false"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="type==='text'"
              #default="validationContext"
              :name="$t('attachment.form.text.label.content')"
              rules="required"
            >
              <text-editor
                id="attachment-text-content"
                v-model="value.content"
                :label="$t('attachment.form.text.label.content').toString()"
                :placeholder="$t('attachment.form.text.label.content-placeholder').toString()"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
                class="mt-n1"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>

          </form>
        </rc-overlay>
      </b-modal>
    </validation-observer>

  </div>
</template>

<script>
import {
  BModal, BFormInvalidFeedback, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, watch } from '@vue/composition-api'
import { url } from '@validations'
import store from '@/store'
import { parseRequestError } from '@/helpers/helpers'
import TextEditor from '@/views/_global/TextEditor'
import RcOverlay from '@/layouts/components/rc/RcOverlay'

require('cleave.js/dist/addons/cleave-phone.pl')

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,

    TextEditor,
    RcOverlay,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    resource: {
      type: String,
      required: true,
    },
    resourceId: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      default: 'file',
      required: false,
    },
    attachmentList: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      url,
    }
  },
  setup(props, { emit }) {
    const attachment = ref(props.value)
    watch(() => props.value, newValue => {
      attachment.value = newValue
    })

    const attachmentType = ref(props.type)
    watch(() => props.type, newValue => {
      attachmentType.value = newValue
    })

    const editAttachmentModal = ref(null)
    const processing = ref(null)

    const changeHandler = () => {
      emit('input', attachment.value)
    }

    const resetAttachment = () => {
      attachment.value = {}
    }

    const dispatch = () => new Promise((resolve, reject) => {
      store.dispatch(
        'attachment/patchAttachment',
        {
          type: attachmentType.value,
          resource: props.resource,
          payload: props.value,
        },
      )
        .then(response => resolve(response))
        .catch(err => reject(err))
    })

    const updateAttachmentInList = editedAttachment => {
      props.attachmentList.forEach((item, key) => {
        if (editedAttachment.id === item.id) {
          // eslint-disable-next-line no-param-reassign
          props.attachmentList[key] = editedAttachment
          // attachments.value = cloneNested(attachments.value)
        }
      })
    }
    const editAttachment = () => {
      processing.value = true
      dispatch()
        .then(response => {
          emit('input', response.data)
          updateAttachmentInList(response.data)
          resetAttachment()
          editAttachmentModal.value.hide()
        })
        .catch(parseRequestError)
        .finally(() => {
          processing.value = false
        })
    }

    const {
      refFormObserver: refEditAttachmentFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetAttachment)

    return {
      editAttachmentModal,
      processing,

      editAttachment,
      resetAttachment,

      // validations
      refEditAttachmentFormObserver,
      getValidationState,
      resetForm,
      clearForm,

      attachment,
      changeHandler,
    }
  },
}
</script>

<style scoped>

</style>
