import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import { parseRequestError } from '@/helpers/helpers'
import i18n from '@/libs/i18n'
import store from '@/store'

export default function useAttachment(emit) {
  const editingAttachment = ref(null)
  const attachmentList = ref([])

  const addType = ref('file')
  const editType = ref(null)

  const groupAttachments = attachments => {
    if (typeof attachments === 'undefined' || (Array.isArray(attachments) && attachments.length === 0)) {
      return {}
    }

    const localAttachments = {}
    attachments.forEach(attachment => {
      if (typeof localAttachments[attachment.type] === 'undefined') {
        localAttachments[attachment.type] = []
      }

      localAttachments[attachment.type].push(attachment)
    })

    return localAttachments
  }

  const addAttachment = (attachments, type = 'file') => {
    attachmentList.value = attachments ?? []
    addType.value = type
  }

  const editAttachment = (attachment, attachments, type = 'file') => {
    editingAttachment.value = attachment
    attachmentList.value = attachments
    editType.value = type
  }

  const handleNewAttachment = () => {}
  const handleEditAttachment = () => {}
  const download = attachment => {
    store.dispatch('attachment/downloadAttachmentFile', attachment.id)
      .then(response => {
        const contentDisposition = response.headers['content-disposition']
        const contentType = response.headers['content-type']
        const match = contentDisposition.match(/filename\s*=(.+)/i)
        const filename = match[1]

        const blob = new Blob([response.data], { type: contentType })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', filename)
        link.click()
      })
      .catch(err => {
        parseRequestError(err)
      })
  }

  const deleteAttachment = (attachmentToRemove, attachments) => {
    Swal.fire({
      title: i18n.t('shared.alert.delete.title'),
      text: i18n.t('shared.alert.delete.subtitle', { what: attachmentToRemove.title }),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: i18n.t('shared.alert.delete.btn-yes'),
      cancelButtonText: i18n.t('shared.alert.delete.btn-no'),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        emit('start-processing')
        attachments.forEach((attachment, key) => {
          if (attachmentToRemove.id === attachment.id) {
            attachments.splice(key, 1)
          }
        })
        store.dispatch('attachment/deleteAttachment', attachmentToRemove.id)
          .catch(err => {
            parseRequestError(err)
            emit('fetch-attachments')
          })
          .finally(() => {
            emit('stop-processing')
          })
      }
    })
  }

  return {
    editingAttachment,

    addType,
    editType,
    attachmentList,

    groupAttachments,
    editAttachment,
    addAttachment,
    handleNewAttachment,
    handleEditAttachment,
    deleteAttachment,
    download,
  }
}
