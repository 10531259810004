<template>
  <div>
    <b-form-group
      :label="label"
      :label-for="id"
    >
      <quill-editor
        :id="id"
        v-model="localModel"
        :options="editorOptions"
        @change="changeHandler"
        @input="inputHandler"
        @blur="blurHandler"
      />
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    BFormGroup,
    quillEditor,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'full',
    },
    id: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const localModel = ref(props.value)

    watch(() => props.value, newValue => {
      localModel.value = newValue
    })

    const changeHandler = () => {
      emit('change', localModel.value)
    }

    const inputHandler = () => {
      emit('input', localModel.value)
    }

    const blurHandler = () => {
      const el = document.querySelector('.ql-tooltip [data-link]')

      if (!el) {
        return
      }

      el.setAttribute('placeholder', 'https://totedi.com')
      el.setAttribute('data-link', '')
    }

    const toolbarOptions = {
      full: [
        [{ header: [false, 1, 2, 3] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons

        [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        ['blockquote', 'code-block'],
        ['link', 'image'],
        ['clean'], // remove formatting button
      ],
      simple: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons

        [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['clean'], // remove formatting button
      ],
    }

    const editorOptions = {
      modules: {
        clipboard: {
          matchVisual: false,
        },
        toolbar: {
          // container: '#toolbar',
          container: toolbarOptions[props.variant],
          handlers: {
            image: () => {
              // eslint-disable-next-line no-alert
              alert('Niestety Totedi nie obsługuje jeszcze obrazków zawartych w treści. \nInteresuje Cię taka funkcjonalność? \nNapisz do nas na hi@totedi.com')
            },
          },
        },
      },
      placeholder: props.placeholder,
    }

    return {
      localModel,
      changeHandler,
      inputHandler,
      blurHandler,
      editorOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
