<template>
  <div>

    <validation-observer
      ref="refAddAttachmentFormObserver"
      #default="{ handleSubmit }"
    >
      <b-modal
        id="add-attachment-modal"
        ref="addAttachmentModal"
        size="lg"
        :title="$t('attachment.add-new')"
        :cancel-title="$t('shared.btn.cancel')"
        cancel-variant="outline-secondary"
        :ok-title="$t('shared.btn.add')"
        :ok-disabled="processing"
        @ok.prevent="handleSubmit(addAttachment)"
        @show="resetAttachment"
      >
        <rc-overlay
          :show="processing"
          no-fade
          variant="white"
          class="h-100"
        >
          <form
            id="add-attachment-form"
            autocomplete="off"
            @submit.stop.prevent
          >

            <b-tabs
              v-model="currentTabIndex"
              content-class="mt-2"
              justified
            >
              <b-tab
                v-if="types.includes('file')"
                :title="$t('attachment.file.title')"
                lazy
              >
                <div v-if="uploadingProgress" />
                <validation-provider
                  #default="validationContext"
                  :name="$t('attachment.form.file.label.title')"
                  rules="required|min:2"
                >
                  <b-form-group
                    :label="$t('attachment.form.file.label.title')"
                    label-for="attachment-file-title"
                  >
                    <b-form-input
                      id="attachment-file-title"
                      v-model="attachment.title"
                      type="text"
                      autocomplete="off"
                      minlength="2"
                      maxlength="250"
                      spellcheck="false"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  :name="$t('attachment.form.file.label.file')"
                  rules="required|size:100000"
                >
                  <b-form-group
                    :label="$t('attachment.form.file.label.file')"
                    label-for="attachment-file-file"
                  >
                    <b-form-file
                      id="attachment-file-file"
                      v-model="file"
                      :accept="acceptedFileMimeTypes"
                      :state="getValidationState(validationContext)"
                      :placeholder="$t('attachment.form.file.label.file-placeholder')"
                      :browse-text="$t('shared.btn.browse')"
                      :drop-placeholder="$t('shared.drop-placeholder')"
                      @input="handleFileInput"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

              </b-tab>

              <b-tab
                v-if="types.includes('link')"
                :title="$t('attachment.link.title')"
                lazy
              >

                <b-form-group
                  :label="$t('attachment.form.link.label.title')"
                  label-for="attachment-link-title"
                >
                  <b-form-input
                    id="attachment-link-title"
                    v-model="attachment.title"
                    type="text"
                    autocomplete="off"
                    minlength="2"
                    maxlength="250"
                    spellcheck="false"
                  />
                </b-form-group>

                <validation-provider
                  #default="validationContext"
                  :name="$t('attachment.form.link.label.url')"
                  rules="required|url"
                >
                  <b-form-group
                    :label="$t('attachment.form.link.label.url')"
                    label-for="attachment-url"
                  >
                    <b-form-input
                      id="attachment-url"
                      v-model="attachment.url"
                      type="text"
                      autocomplete="off"
                      minlength="2"
                      maxlength="250"
                      spellcheck="false"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

              </b-tab>

              <b-tab
                v-if="types.includes('text')"
                :title="$t('attachment.text.title')"
                lazy
              >

                <validation-provider
                  #default="validationContext"
                  :name="$t('attachment.form.text.label.title')"
                  rules="required|min:2"
                >
                  <b-form-group
                    :label="$t('attachment.form.text.label.title')"
                    label-for="attachment-text-title"
                  >
                    <b-form-input
                      id="attachment-text-title"
                      v-model="attachment.title"
                      type="text"
                      autocomplete="off"
                      minlength="2"
                      maxlength="250"
                      spellcheck="false"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  :name="$t('attachment.form.text.label.content')"
                  rules="required"
                >
                  <text-editor
                    id="attachment-text-content"
                    v-model="attachment.content"
                    :label="$t('attachment.form.text.label.content').toString()"
                    :placeholder="$t('attachment.form.text.label.content-placeholder').toString()"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                    class="mt-n1"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

              </b-tab>
            </b-tabs>

          </form>
        </rc-overlay>
      </b-modal>
    </validation-observer>

  </div>
</template>

<script>
import {
  BModal, BFormInvalidFeedback, BFormInput, BFormGroup, BTabs, BTab, BFormFile,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, watch, computed } from '@vue/composition-api'
import { url } from '@validations'
import _ from 'lodash'
import store from '@/store'
import { parseRequestError } from '@/helpers/helpers'
import TextEditor from '@/views/_global/TextEditor'
import RcOverlay from '@/layouts/components/rc/RcOverlay'

require('cleave.js/dist/addons/cleave-phone.pl')

export default {
  components: {
    BModal,
    BFormInput,
    BFormFile,
    BFormGroup,
    BFormInvalidFeedback,

    BTabs,
    BTab,

    TextEditor,
    RcOverlay,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    resource: {
      type: String,
      required: true,
    },
    resourceId: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      default: 'file',
      required: false,
    },
    types: {
      type: Array,
      default: () => ['file', 'link', 'text'],
    },
    attachmentList: {
      type: Array,
      default: () => [],
      required: false,
    },
    attachmentOrganizationId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      url,
    }
  },
  setup(props, { emit }) {
    const attachment = ref({})
    const file = ref(null)
    const addAttachmentModal = ref(null)
    const processing = ref(null)
    const uploadingProgress = ref(null)
    const attachmentType = ref(props.type)

    const tabNameIndex = { file: 0, url: 1, text: 2 }
    const currentTabIndex = ref(0)
    const currentAttachmentType = computed(() => _.invert(tabNameIndex)[currentTabIndex.value])

    const acceptedFileMimeTypes = ref([
      // images
      'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml',

      // multimedia
      'audio/*', 'video/*',

      // compressed
      'application/x-bzip', 'application/x-bzip2', 'application/gzip', 'application/vnd.rar', 'application/zip', 'application/x-7z-compressed',

      // documents
      'text/plain', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/epub+zip', 'application/vnd.oasis.opendocument.presentation', 'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.text', 'application/pdf', 'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/rtf', 'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ].join(', '))

    watch(() => props.type, newValue => {
      attachmentType.value = newValue
    })

    const resetAttachment = () => {
      currentTabIndex.value = tabNameIndex[attachmentType.value]
      attachment.value = {}
      file.value = null
      uploadingProgress.value = null
    }

    const dispatchUpload = () => store.dispatch(
      'attachment/uploadFile',
      {
        file: file.value,
        attachmentOrganizationId: attachment.value.attachmentOrganizationId,
        progressCallback: progressEvent => {
          uploadingProgress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        },
      },
    )

    const dispatch = () => new Promise((resolve, reject) => {
      if (props.attachmentOrganizationId) {
        attachment.value.attachmentOrganizationId = props.attachmentOrganizationId
      }

      if (currentAttachmentType.value !== 'file') {
        store.dispatch(
          'attachment/addAttachment',
          {
            type: currentAttachmentType.value,
            resource: props.resource,
            resourceId: props.resourceId,
            payload: attachment.value,
          },
        )
          .then(response => resolve(response))
          .catch(err => reject(err))
      } else {
        dispatchUpload()
          .then(uploadedFileResponse => {
            attachment.value.file = uploadedFileResponse.data.id
            store.dispatch(
              'attachment/addAttachment',
              {
                type: currentAttachmentType.value,
                resource: props.resource,
                resourceId: props.resourceId,
                payload: attachment.value,
              },
            )
              .then(response => resolve(response))
              .catch(err => reject(err))
          })
          .catch(err => reject(err))
      }
    })

    const addAttachmentToList = addedAttachment => {
      props.attachmentList.push(addedAttachment)
    }

    const handleFileInput = event => {
      if (!attachment.value.title && event.name) {
        attachment.value.title = event.name.replace(/\.[^/.]+$/, '')
      }
    }

    const addAttachment = () => {
      processing.value = true
      dispatch()
        .then(response => {
          emit('attachment-added', response.data)
          addAttachmentToList(response.data)
          resetAttachment()
          addAttachmentModal.value.hide()
        })
        .catch(parseRequestError)
        .finally(() => {
          processing.value = false
        })
    }

    const {
      refFormObserver: refAddAttachmentFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetAttachment)

    return {
      attachment,
      file,
      addAttachmentModal,
      processing,
      currentAttachmentType,
      uploadingProgress,
      currentTabIndex,
      acceptedFileMimeTypes,
      handleFileInput,

      addAttachment,
      resetAttachment,

      // validations
      refAddAttachmentFormObserver,
      getValidationState,
      resetForm,
      clearForm,
      tabNameIndex,
    }
  },
}
</script>

<style scoped>

</style>
