<template>
  <b-card
    no-body
    class="link-preview d-block pb-5"
  >
    <b-card-body>
      <b-card-title>

        <b-link
          :href="attachment.url"
          rel="nofollow"
          target="_blank"
        >
          {{ attachment.title }}
        </b-link>
      </b-card-title>
      <b-card-sub-title>{{ attachment.preview.title }}</b-card-sub-title>
    </b-card-body>

    <b-link
      v-if="attachment.preview.image"
      :href="attachment.url"
      rel="nofollow"
      target="_blank"
      class="text-center d-block"
    >
      <b-img
        fluid
        :src="attachment.preview.image"
        :alt="attachment.preview.title"
      />
    </b-link>

    <b-card-body v-if="attachment.preview.description">
      <b-card-text>{{ attachment.preview.description }}</b-card-text>
    </b-card-body>

    <b-card-footer>

      <div class="float-right">
        <b-link
          v-if="enableDelete"
          class="text-danger my-1 ml-1"
          @click="$emit('delete-attachment', attachment)"
        >
          <feather-icon
            icon="TrashIcon"
          />
        </b-link>

        <b-link
          v-if="enableEdit"
          v-b-modal.edit-attachment-modal
          class="text-primary my-1 ml-1"
          @click="$emit('edit-attachment', attachment, 'url')"
        >
          <feather-icon
            icon="EditIcon"
          />
        </b-link>
      </div>

      <b-link
        class="card-link"
        :href="attachment.url"
        rel="nofollow"
        target="_blank"
      >
        {{ $t('shared.btn.open') }}
      </b-link>

    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody, BCardFooter, BCardSubTitle, BCardText, BCardTitle, BImg, BLink, VBModal,
} from 'bootstrap-vue'

export default {
  name: 'UrlAttachmentPreview',
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BCardFooter,
    BCardSubTitle,
    BLink,
    BImg,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    enableDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.link-preview {
  .card-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
}
</style>
